.ticketItem {
    display: flex;
    flex-direction: column;
}
.textInfor {
    font-size: 16px;
}
.inforWithIcon {
    display: flex;
    gap: 10px;
}