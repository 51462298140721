.bigCard {
    background-color: white;
    box-shadow: 
    0px 3px 6px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 800px;
    min-height: 600px;
}
.bigCard_title {
    margin-block: 0;
    padding: 24px 32px 0;
    font-weight: 700;
}
.bookingResultContainer {
    padding-inline: 50px;
    width: 100%;
    display: flex;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(444px, 1fr)); */
    gap: 24px;
    flex: 1;
    max-height: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.scrollWrapper {
    background-color: white;
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.scrollContainer {
    padding: 0px 30px;
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
}
.invoiceContainer {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
    background-color: #F1F1F1;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
}
.btnsContainer {
    padding: 10px 32px;
}
.checkoutBtn {
    font-weight: 600;
    background-color: rgba(0, 91, 165, 1);
    color: white;
    min-height: 40px;
    border: none;
}
.checkoutBtn:hover {
    color: white !important;
    background-color: rgb(2, 111, 201);
}
@media screen and (max-width: 520px) {
    .bookingResultContainer{
        padding-inline: 10px;
    }
    .scrollContainer, .bigCard_title {
        padding-inline: 16px;
    }
}