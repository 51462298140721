.busOption {
    display: flex;
    justify-content: space-between;
}
.busInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.busPrice {
    text-align: right;
}