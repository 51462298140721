.ticketOperatorContainer {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    width: clamp(16rem, 96%, 110rem);
    margin-inline: auto;
} 
.ticketOperatorContainer_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
} 
.title {
    font-size: 22px;
    font-weight: 500;
}
.filterContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px 20px;
    flex-wrap: wrap;
} 
.filterItemContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.table {
    width: 100%;
}
.collectionFee_modal_container {
    max-height: 70dvh;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    padding-inline: 4px;
    column-gap: 20px;
}
.collectionFee_modal_methodForm_container {
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 24px;
    margin-bottom: 8px;
}
.formItemGroup {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}
.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
