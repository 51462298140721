.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.buttonsContainer {
    display: flex;
    justify-content: flex-end;
}
.justifyBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.billPrice {
    font-size: 20px;
}