.motorbikeContainer {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    width: clamp(16rem, 96%, 100rem);
    margin-inline: auto;
} 
.table {
    width: 100%;
}
.btnContainer {
    display: flex;
    gap: 20px;
    width: calc(80%);
    justify-content: space-between;
    margin-inline: auto;
}
.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: .6rem .5rem;
    width: 100%;
    flex-wrap: wrap;
}
.filterButtons {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 16px;
    padding-bottom: 4px;
}
.filterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    padding: 2px 20px;
    border-radius: 6px;
    background-color: white;
    min-width: 126px;
}
.filterButton:hover {
    background-color: rgba(255, 255, 255, 0.699);
}