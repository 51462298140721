.bookingRevenueContainer {
    display: flex;
    padding-block: 10px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: calc(100%);
    max-height: 1020px;
    /*z-index: 6; */
}
.container {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: clamp(16rem, 90vw, 70rem);
}
.containerHead {
    min-height: 50px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    /* border-bottom: 1px solid rgba(216, 220, 221, 1); */
}
.titleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    height: fit-content;
}
.performanceList {
    padding: .5rem .5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 10px;
    /* border-top: 1px solid rgba(216, 220, 221, 1);
    border-left: 1px solid rgba(216, 220, 221, 1); */
    /* grid-template-rows: repeat(auto-fit, minmax(150%, 1fr)); */
    /* grid-auto-flow: column; */
}

.bookingRevenueWrapper {
    flex: 1;
}
.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    max-width: 600px;
    min-width: 400px;
    flex: 1;
    margin-inline: auto;
}
.listContainer_header {
    background-color: rgba(227, 239, 252, 0.699);
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    width: 100%;
} 
.listWrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.listWrapper_List {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 10px 10px 16px;
    scroll-behavior: smooth;
}
.listContainer_footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;
    width: 100%;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 13px 16px;
    width: 100%;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
}
.bookingItem {
    display: flex;
    background: #FFFFFF;
    justify-content: space-between;
    opacity: 0.8;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    cursor: pointer;
}
.bookingItem:hover {
    background-color: #f3f9ffb9;
}
.bookingItem.bookingSelected {
    background-color: #F3F9FF;
    border: 1px solid #7D91BC;
}
.bookingItem_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 8px;
}
.textWithIcon {
    display: flex;
    gap: 8px;
    align-items: center;
}
.btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #D9D9D9;
    flex-basis: 40px;
    gap: 20px;
}
@media screen and (max-width: 520px) {
    .container {
        width: 100%;
    }
    .listContainer {
        min-width: 340px;
    }
    .listContainer:nth-of-type(2) {
        margin-bottom: 20px;
    }
    .listWrapper {
        min-height: 165px;
    }
    .perfomanceContainer {
        width: 100%;
    }
    .listContainer {
        height: max-content;
    }
}
@media screen and (min-width: 1200px) {
    .bookingRevenueContainer {
        flex-direction: unset;
        align-items: unset;
        max-width: 1700px;
        margin-inline: auto;
    }
    .bookingRevenueWrapper > .container {
        width: unset;
        max-width: 1500px;
    }
    .perfomanceContainer > .container:nth-of-type(1) {
        width: clamp(12rem, 22vw, 25rem);
    }
    .performanceList {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: calc(100% - 68px);
        overflow-y: auto;
    }
    .bookingRevenueWrapper .listContainer {
        min-width: 320px;
        max-width: 700px;
    }
}