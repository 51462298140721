.bigCard {
    background-color: white;
    box-shadow: 
    0px 3px 6px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    width: clamp(400px, 30%, 550px);
}
.bigCard_title {
    margin-block: 0;
    padding: 24px 32px 0;
    font-weight: 700;
}
.toursWrapper {
    flex: 1 1 auto;
    height: 0px;
    width: 100%;
}
.tours {
    padding: 0 16px 12px;
    max-height: 100%;
    /* max-height: 150px; */
    overflow-y: auto;
}
.ticketsWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.377);
}
.totalWrapper {
    padding: 12px 16px;
    background-color: rgba(241, 241, 241, 1);
}
.totalItem {
    display: flex;
    justify-content: space-between;
    padding-block: 3px;
    flex-wrap: wrap;
    row-gap: 6px;
}
.busAndMoneyContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.busContainer {
    width: 50%;
    display: flex;
    gap: 5px;
    align-items: center;
}
.busInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.titleWrapper {
    padding: 12px 16px;
    background-color: rgba(241, 241, 241, 1);   
}
.payeeForm {
    padding: 6px 32px 12px;
    width: calc(100% - 4px);
    overflow-y: auto;
}
.paymentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.paymentWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1 1 auto;
    height: 0px;
    min-height: 300px;
}
.paymentBtnContainer {
    padding: 0 30px 12px;
    display: flex;
    justify-content: flex-end;
}
.methodForm {
    padding: 0 20px 12px;
    overflow-y: auto;
    width: calc(100% - 4px);
}
.justifyBetween {
    display: flex;
    justify-content: space-between;
}
.billPrice {
    font-size: 20px;
}
.commonBtn {
    font-weight: 600;
    background-color: rgba(0, 91, 165, 1);
    color: white;
    min-height: 40px;
    border: none;
}
.commonBtn:hover {
    color: white !important;
    background-color: rgb(2, 111, 201);
}
.methodContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}
.methodWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.methodImg {
    width: 70px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.methodFormItem {
    margin-bottom: 0;
}
.payeeContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    height: 0px;
    min-height: 300px;
}
.payeeBtnContainer {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px 12px;
}
/* ticket with QR */
.ticketWithQR {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #005BA5;
    flex-wrap: wrap-reverse;
}
.ticketWithQR.ticketErased {
    position: relative;
}
.ticketWithQR.ticketErased .ticketWithQR_wrapper,
.ticketWithQR.ticketErased .QRContainer {
    opacity: .6;
}
.ticketWithQR.ticketErased::before {
    --f: 10px; /* control the folded part*/
    --r: 5px; /* control the ribbon shape */
    --t: 10px; 
    content: 'Cancelled';
    position: absolute;
    color: white;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.747);
    inset: var(--t) calc(-1*var(--f)) calc(100% - 45px) auto;
    padding: 2px 10px var(--f) calc(10px + var(--r));
    clip-path: 
        polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    z-index: 2;
}
.ticketWithQR.ticketErased::after {
    content: '';
    position: absolute;
    border: 1px solid black;
    inset: 0;
    background-color: rgb(90, 90, 90);
    opacity: .2;
    z-index: 1;
}
.ticketWithQR_wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px 12px;
    gap: 9px;   
    justify-content: center;
    flex: 3;
    /* border-right: 1px solid #005BA5; */
}
.ticketWithQR_item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 6px;
}
.inforWithIcon {
    display: flex;
    gap: 8px;
}
.ticketWithQR_item_header {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%,
}
.ticketWithQR_item_header_info {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%,
}
.QRContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-inline: 12px; */
    /* flex: 1 */
    /* width: 100%; */
}
.dividerContainer {
    width: 100%;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fullWidthDivider {
    width: 100%;
    height: 1px;
    background-color: #000000;
    opacity: 0.8;
}
.shortDivider {
    width: 80%;
    height: 1px;
    background-color: #000000;
    opacity: 0.8;
}
.ticketWithQR_bus {
    display: flex;
    align-items: center;
    gap: 8px;
}
.payDetailModal {
    max-height: 70dvh;
    overflow-y: auto;
    width: 100%;
}
.totalMoneyContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.totalTitle {
    width: 54%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.totalValue {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-end;
}
.ending {
    width: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    padding-bottom: 10px;
}
.extraServiceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
}
.extraServiceItem_container {
    display: flex;
    flex-direction: column;
}
.extraServiceItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 4px;
    align-items: center;
}
.jasmine-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 91, 165, 1);
    padding: 10px;
    border-radius: 10px;
}
.iconStyle {
    font-size: 14px;
}
.fullNameStyle {
    font-size: 19px;
    font-weight: 700;
}
@media screen and (max-width: 520px) {
    .bigCard, .payeeContainer, .toursWrapper {
        height: max-content;
    }
    .paymentWrapper {
        min-height: 600px;
    }
    .payeeForm {
        padding-inline: 10px;
    }
}
@media screen and (max-width: 675px) {
    .ticketWithQR {
        /* flex-direction: column-reverse; */
        padding-block: 10px;
    }
    .ticketWithQR_wrapper {
        border-right: none !important;
        padding-top: 8px;
    }
    .ticketWithQR_wrapper .totalItem{
        flex-wrap: nowrap;
    }
}