.container {
    max-height: 100%;
    min-height: 100%;
    height: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    padding-inline: clamp(1.5rem, 1%, 5rem);
    gap: 16px;
}
.wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 20px;
}
.listContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    overflow: hidden;
    flex: 1;
    max-width: 1050px;
}
.listContainer_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 0;
}
.detailContainer {
    flex: 1;
    width: 100%;
}
.detailWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 24px;
    padding: 12px 24px;
    flex-wrap: wrap;
}
.detailWrapper_column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    min-height: 600px;
}
.detailWrapper_item {
    flex: 1;
    width: 100%;
    background: #FFFFFF;
/* Neutral/5 */
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}
/* detail view */
.detailView {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* max-height: 100%; */
    display: flex;
    flex-direction: column;
}
.detailView_title {
    background-color: rgba(227, 239, 252, 0.699);
    /* height: 0px; */
    display: flex;
    padding: 8px 12px;
    width: 100%;
}
.detailView_footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #D9D9D9;
    padding: 8px 12px;
    background-color: white;
    z-index: 2;
}
.checkboxContainer {
    display: flex;
    gap: 8px;
}
.checkboxItem {
    min-width: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkboxItem:nth-of-type(2) {
    min-width: 40px;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detailView_wrapper {
    width: 100%;
    flex: 1 1 auto;
    height: 0px;
}
.scrollContainer {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 24px;
}
.customerScrollContainer {
    overflow-y: auto;
    flex: 1;
    max-height: 100%;
    width: calc(100% - 4px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 8px 8px 12px  ;
}
.dateWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97%;
}
.dateWrapper_item {
    display: flex;
    gap: 8px;
    align-items: center;
}
.btnGroup {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 0px 8px;
    margin-block: 4px;
}
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #91D5FF;
}
.inputGroup {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}
.customerContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
}
.customerContainer.disabled .customerWrapper,
.customerContainer.disabled .customerEvenColumn {
    color: #BFBFBF;
}
.customerContainer.warning .customerWrapper {
    border-color: red;
}
.customerWrapper {
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding-block: 4px;
}
.inforWithIcon {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
}
.textEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.customerRow {
    padding: 4px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.customerEvenColumn {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.jobItem:hover {
    color: #1677ff;
}
.journeyIcon {
    width: 32px;
    height: 32px;
    background-color: #BAE7FF;
    color: #1890FF;
    font-size: 14px;
    border-radius: 50%;
}
@media screen and (max-width: 1200px) {
    .wrapper {
        align-items: center;
        flex-direction: column;
    }
}
@media screen and (max-width: 500px) {
    .container {
        padding: 2.5rem 0 0;
    }
    .listContainer_header,
    .detailWrapper {
        padding-inline: 8px;
    }
    .scrollContainer {
        padding: 8px !important;
    }
}